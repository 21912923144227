export interface IComment {
  name: string;
  postedAt: string;
  text: string | React.ReactNode;
}
export enum FeedbackTypeId {
  comment = 1,
  addEvaluation = 2,
  editEvaluation = 3,
  deleteEvaluation = 4,
}

export interface IGetFeedback {
  userId: number;
  name: string;
  feedbackTypeId: FeedbackTypeId;
  data: {
    job: {
      title: string;
    };
    evaluationId: number;
    kitTitle: string;
    stageName: string;
    comment?: string
  };
  createdAt: string;
  isLastVersion?: Boolean
}

export interface IAddComment {
  applicationId: number;
  text: string;
  mentionedUserIds: {id: number}[];
}
