import { createSlice } from "@reduxjs/toolkit";
import { CheckboxDropdownItem } from "../../../shared/utils/interfaces/settingsInterface";
import { IKit } from "../../../shared/utils/interfaces/interviewKit";

const interviewSlice = createSlice({
  name: "interview",
  initialState: {
    isInterviewKitNameModalOpen: false,
    isKitSaved: false,
    selectedKit: {},
    isGenerateNewKit: false,
    allKits: [],
    evaluationSections: [],
    showPreview: false,
    evaluationView: false,
    selectedKitTemplateData: {},
    generateSectionWithAI: false,
    deleteEvaluationModal: false,
    isEvaluationKitModalOpen: false,
    isEditingKit: false,
    evaluationId: undefined,
    isLoadingEvaluationData: false,
    selectedTemplate: {},
    templates: [],
    isCreatingNewTemplate: false,
    selectedTemplateData: {},
    oldSelectedTemplateData: {},
    isClickingUseContent: false,
  },
  reducers: {
    setInterviewKitNameModalOpen: (state, action) => {
      state.isInterviewKitNameModalOpen = action.payload;
    },
    setKitSaved: (state, action) => {
      state.isKitSaved = action.payload;
    },
    setGenerateNewKit: (state, action) => {
      state.isGenerateNewKit = action.payload;
    },
    setSelectedKit: (state, action) => {
      state.selectedKit = action.payload;
    },
    setAllKits: (state, action) => {
      state.allKits = action.payload;
    },
    setEvaluationSections: (state, action) => {
      state.evaluationSections = action.payload;
    },
    setShowPreview: (state, action) => {
      state.showPreview = action.payload;
    },
    setEvaluationView: (state, action) => {
      state.evaluationView = action.payload;
    },
    setSelectedKitTemplateData: (state, action) => {
      state.selectedKitTemplateData = action.payload;
    },
    setIsEditingKit: (state, action) => {
      state.isEditingKit = action.payload;
    },
    setGenerateSectionWithAI: (state, action) => {
      state.generateSectionWithAI = action.payload;
    },
    setDeleteEvaluationModal: (state, action) => {
      state.deleteEvaluationModal = action.payload;
    },
    setEvaluationKitModal: (state, action) => {
      state.isEvaluationKitModalOpen = action.payload;
    },
    setEvaluationId: (state, action) => {
      state.evaluationId = action.payload;
    },
    setIsLoadingEvaluationData: (state, action) => {
      state.isLoadingEvaluationData = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setIsCreatingNewTemplate: (state, action) => {
      state.isCreatingNewTemplate = action.payload;
    },
    setSelectedTemplateData: (state, action) => {
      state.selectedTemplateData = action.payload;
    },
    setOldSelectedTemplateData: (state, action) => {
      state.oldSelectedTemplateData = action.payload;
    },
    setIsClickingUseContent: (state, action) => {
      state.isClickingUseContent = action.payload;
    },
  }
});

export const {
  setInterviewKitNameModalOpen,
  setKitSaved,
  setGenerateNewKit,
  setSelectedKit,
  setAllKits,
  setEvaluationSections,
  setShowPreview,
  setEvaluationView,
  setSelectedKitTemplateData,
  setGenerateSectionWithAI,
  setDeleteEvaluationModal,
  setEvaluationKitModal,
  setIsEditingKit,
  setEvaluationId,
  setIsLoadingEvaluationData,
  setSelectedTemplate,
  setTemplates,
  setIsCreatingNewTemplate,
  setSelectedTemplateData,
  setOldSelectedTemplateData,
  setIsClickingUseContent,
} = interviewSlice.actions;

export default interviewSlice.reducer;
export const selectInterviewKitNameModalOpen = (state: {
  interview: { isInterviewKitNameModalOpen: boolean };
}) => state.interview.isInterviewKitNameModalOpen;
export const selectIsKitSaved = (state: { interview: { isKitSaved: boolean } }) =>
  state.interview.isKitSaved;
export const selectSelectedKit = (state: {
  interview: { selectedKit: CheckboxDropdownItem };
}) => state.interview.selectedKit;
export const selectGenerateNewKit = (state: {
  interview: { isGenerateNewKit: boolean };
}) => state.interview.isGenerateNewKit;
export const selectAllKits = (state: { interview: { allKits: CheckboxDropdownItem[] } }) =>
  state.interview.allKits;
export const selectEvaluationSections = (state: {
  interview: { evaluationSections: any };
}) => state.interview.evaluationSections;
export const selectShowPreview = (state: { interview: { showPreview: boolean } }) =>
  state.interview.showPreview;
export const selectEvaluationView = (state: {
  interview: { evaluationView: boolean };
}) => state.interview.evaluationView;
export const selectSelectedKitTemplateData = (state: {
  interview: { selectedKitTemplateData: IKit };
}) => state.interview.selectedKitTemplateData;
export const selectIsEditingKit = (state: {
  interview: { isEditingKit: boolean };
}) => state.interview.isEditingKit;
export const selectGenerateSectionWithAI = (state: {
  interview: { generateSectionWithAI: boolean };
}) => state.interview.generateSectionWithAI;
export const selectDeleteEvaluationModal = (state: {
  interview: { deleteEvaluationModal: boolean };
}) => state.interview.deleteEvaluationModal;
export const selectEvaluationId = (state: {
  interview: { evaluationId: number }
}) => state.interview.evaluationId;
export const selectIsLoadingEvaluationData = (state: {
  interview: { isLoadingEvaluationData: boolean }
}) => state.interview.isLoadingEvaluationData;
export const selectIsEvaluationKitOpen = (state: {
  interview: { isEvaluationKitModalOpen: boolean };
}) => state.interview.isEvaluationKitModalOpen;
export const selectAllTemplates = (state: {
  interview: { templates: CheckboxDropdownItem[] }
}) => state.interview.templates;
export const selectIsCreatingNewTemplate = (state: {
  interview: { isCreatingNewTemplate: boolean }
}) => state.interview.isCreatingNewTemplate;
export const selectSelectedTemplateData = (state: {
  interview: { selectedTemplateData: IKit };
}) => state.interview.selectedTemplateData;
export const selectOldSelectedTemplateData = (state: {
  interview: { oldSelectedTemplateData: IKit };
}) => state.interview.oldSelectedTemplateData;
export const selectIsClickingUseContent = (state: {
  interview: { isClickingUseContent:boolean };
}) => state.interview.isClickingUseContent;
export const selectSelectedTemplate = (state: { interview: { selectedTemplate: CheckboxDropdownItem } }) => state.interview.selectedTemplate;