import { useEffect, useState } from "react";
import { IGetFeedback, FeedbackTypeId } from "../../../../../shared/utils/interfaces/commentsFaces";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ProfileImage from "../../../../../shared/components/NavBar/profileImage";
import { ReactComponent as ClipboardList } from '../../../../../shared/assets/images/ClipboardList.svg';
import { ReactComponent as Line } from '../../../../../shared/assets/images/Line.svg'
import { selectCurrentUser } from "../../../../authentication/slice/authSlice";
import { useDispatch, useSelector } from 'react-redux'
import { setDeleteEvaluationModal, selectDeleteEvaluationModal, selectIsLoadingEvaluationData, selectEvaluationId } from "../../../../interview/slice/interviewSlice"
import { Button } from "antd";
export default function FeedbackEvaluation({ feedback, onShowPreview, onDelete, onEdit }: { feedback: IGetFeedback, onShowPreview: (evaluationId: number) => void, onDelete: (evaluationId: number) => void, onEdit: (evaluationId: number) => void }) {

    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const deleteModalOpen = useSelector(selectDeleteEvaluationModal)
    const isLoadingEvaluationData = useSelector(selectIsLoadingEvaluationData)
    const evaluationId = useSelector(selectEvaluationId)
    const isEditable = feedback?.userId === user?.id
    const [postedSince, setPostedSince] = useState<string>();
    const [isClickEditing, setIsClickEditing] = useState(false)
    useEffect(() => {
        dayjs.extend(relativeTime)
        setPostedSince(dayjs().to(dayjs(feedback?.createdAt)))
    }, [feedback?.createdAt])
    const handelToggleDeleteModal = () => {
        onDelete(feedback?.data?.evaluationId)
        dispatch(setDeleteEvaluationModal(!deleteModalOpen))
    }
    const showPreviews = () => {
        setIsClickEditing(false)
        onShowPreview(feedback?.data?.evaluationId);
    }
    const handleOpenEditEvaluationModal = () => {
        setIsClickEditing(true)
        onEdit(feedback?.data?.evaluationId)
    }

    return (
        <>
            <div className='flex flex-col items-start gap-2 mt-4 mb-5 flex-nowrap pe-2 sm:flex-row '>
                <ProfileImage name={feedback?.name} className="me-3.5 -mt-1" />
                <div>
                    <div className='flex sm:items-center sm:flex-row flex-nowrap mb-1.5 gap-2 flex-col items-start'>
                        <span className='text-sm font-semibold text-gray-600 break-all me-2'>{feedback?.name}</span>
                        <span className='text-xs text-gray-600 break-words min-w-fit'>{
                            `${feedback?.feedbackTypeId === FeedbackTypeId.addEvaluation ? `Added ` : feedback?.feedbackTypeId === FeedbackTypeId.editEvaluation ? 'Updated' : 'Deleted'} evaluation for the`
                        }
                            <span className="capitalize">{` ${feedback?.data?.job?.title} `}</span>
                            job
                        </span>
                        <span className='text-xs font-normal text-gray-400 whitespace-nowrap'>{postedSince}</span>

                    </div>
                    {
                        feedback?.isLastVersion && feedback?.feedbackTypeId !== FeedbackTypeId.deleteEvaluation ?
                            <div className="flex flex-col items-start gap-5 sm:items-center sm:flex-row">
                                <Button className={`flex items-center gap-2 text-sm text-gray-600  max-w-fit !py-8 !border-none rounded-md ${isEditable ? `bg-gray-200` : `border border-gray-300 cursor-not-allowed`}`}
                                    onClick={showPreviews} loading={isLoadingEvaluationData && feedback?.data?.evaluationId === evaluationId && !isClickEditing}>
                                    <ClipboardList />
                                    <div className="cursor-pointer">
                                        <p>{feedback?.data?.kitTitle}</p>
                                        {/* <p>Evaluation score <span className="font-bold">8/10</span></p> */}
                                    </div>
                                </Button>
                                {isEditable ?
                                    <div className="flex items-center text-xs font-medium">
                                        <Button className="text-indigo-700 border-none shadow-none" loading={isLoadingEvaluationData && feedback?.data?.evaluationId === evaluationId && isClickEditing} onClick={handleOpenEditEvaluationModal}>
                                            Edit
                                        </Button>
                                        <Line className='mx-[0.5rem]' />
                                        <button className="text-red-600" onClick={handelToggleDeleteModal}>
                                            Delete
                                        </button>
                                    </div>
                                    : ''
                                }
                            </div> : ''
                    }
                </div>
            </div>
        </>
    )
}