'use client'

import React, { useState, useEffect, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Divider, Form, Input, message, Select, Spin, Upload, UploadProps } from "antd"
import { useEditor, EditorContent } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import Link from "@tiptap/extension-link"
import Highlight from "@tiptap/extension-highlight"
import { useForm } from "antd/es/form/Form"

import { selectCandidateProfile, selectIsSendEmailOpen, setIsSendEmailOpen } from "../../../slice/candidateSlice"
import { useGetTemplatesByApplicationIDQuery, useSendEmailMutation } from "../../../slice/candiddateApiSlice"
import { selectCurrentUser } from "../../../../authentication/slice/authSlice"
import { selectActiveTemplateTypeId, setActiveTemplateTypeId } from "../../../../settings/slice/templatesSlice"
import { ITemplate } from "../../../../../shared/utils/interfaces/apisInterfaces"
import { apiStatuses, attachments, errorMessage } from "../../../../../shared/utils/constantData"
import { regexPatterns } from "../../../../../shared/utils/regexPatterns"
import { useDemoMode } from "../../../../../shared/utils/customHooks/useDemoHook"
import { getImageUrl } from "../../../../../shared/utils/functions/images"
import TextEditorToolbar from "../../../../../shared/components/TextEditor/TextEditorToolbar"
import RequestError from "../../../../../shared/components/Errors/RequestError"

import { ReactComponent as CaretDownGray } from '../../../../../shared/assets/images/caretDownGray.svg'
import { ReactComponent as Check } from '../../../../../shared/assets/images/Check.svg'
import { ReactComponent as MinimizeIcon } from '../../../../../shared/assets/images/minimizeIcon.svg'
import { ReactComponent as MaximizeIcon } from '../../../../../shared/assets/images/maximizeIcon.svg'
import { ReactComponent as CloseComposeEmail } from '../../../../../shared/assets/images/closeComposeEmail.svg'
import { ReactComponent as AttachmentIcon } from '../../../../../shared/assets/images/attachmentIcon.svg'
import { ReactComponent as LabIcon } from '../../../../../shared/assets/images/lab.svg'
import { ReactComponent as GrayFileText } from '../../../../../shared/assets/images/grayFileText.svg';
import { emailTypes } from "../../../../../shared/utils/interfaces/settingsInterface";
import { reusableBeforeUpload } from "../../../../../shared/utils/functions/filesValidations"

const { Option } = Select

export default function ComposeEmail() {
    const dispatch = useDispatch()
    const [form] = useForm()
    const { isDemoMode } = useDemoMode()

    const [minimized, setMinimized] = useState(false)
    const [ccOn, setCcOn] = useState(false)
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [newVal, setNewVal] = useState('')
    const [selectedMark, setSelectedMark] = useState<HTMLElement | null>(null)
    const [alltempelates, setAllTempelates] = useState<ITemplate[]>([])
    const [sectionPosition, setSectionPosition] = useState<{ top: number; left: number } | null>(null)
    const [inputFieldsValue, setInputFieldsValue] = useState<{ key: string, value: string }[] | []>()
    const [noValueOfferInputsLength, setNoValueOfferInputsLength] = useState(0)
    const [selectedTemplateFields, setSelectedTemplateFields] = useState<string[] | undefined>()
    const candidateEmail = useSelector(selectCandidateProfile)?.email
    const connectedEmail = useSelector(selectCurrentUser)?.connectedEmail
    const isSendEmailOpen = useSelector(selectIsSendEmailOpen)
    const templateTypeId = useSelector(selectActiveTemplateTypeId)
    const candidate = useSelector(selectCandidateProfile)
    const editorRef = useRef<HTMLDivElement>(null)
    const inputRef: any = useRef<HTMLInputElement>(null)
    const [emailFiles, setEmailFiles] = useState<any[]>([]);
    const { data, isError, isSuccess, isLoading } = useGetTemplatesByApplicationIDQuery(
        { applicationId: candidate?.id, templateTypeId },
        { skip: !isSendEmailOpen, refetchOnMountOrArgChange: true }
    )

    const [sendEmail, { isSuccess: emailSuccess, isLoading: EmailLoading, isError: isEmailError, error: emailError }] = useSendEmailMutation()

    const { communication } = emailTypes
    const extensions: any[] = [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        Link.configure({
            openOnClick: false,
            autolink: true,
            linkOnPaste: true,
            HTMLAttributes: {
                class: 'underline',
                target: '_blank'
            }
        })
    ]

    const editor = useEditor({
        extensions,
        content: body,
        onUpdate: ({ editor }) => setBody(editor?.getHTML()),
        editorProps: {
            attributes: {
                class: 'min-h-[7.5rem] max-h-[14rem] p-0 m-0 overflow-auto focus:outline-none',
            },
        },
    })

    const subjectEditor = useEditor({
        extensions,
        content: subject,
        onUpdate: ({ editor }) => setSubject(editor?.getHTML()),
        editorProps: {
            attributes: {
                class: 'p-0 m-0 overflow-x-auto overflow-hidden scrollbar-hide [&_*]:whitespace-nowrap [&_*]:text-base [&_*]:font-normal [&_*]:text-gray-600 focus:outline-none',
            },
        },
    })

    useEffect(() => {
        if (isSuccess) {
            const { companyEmailTemplates, defaultEmailTemplates } = data?.data
            const combinedTemplates: ITemplate[] = [...companyEmailTemplates, ...defaultEmailTemplates]
            setAllTempelates(combinedTemplates)
            setSectionPosition(null)
            resetFields()
            form.resetFields(['template']);
        } else if (isError) {
            message.error(errorMessage)
        }
        // eslint-disable-next-line
    }, [data, isError, isSuccess])

    useEffect(() => {
        if (emailSuccess) {
            setInputFieldsValue([])
            setNoValueOfferInputsLength(0)
            message.success("Email successfully sent")
            dispatch(setIsSendEmailOpen(false))
            resetFields()
            setIsSubmitting(false)
        } else if (isEmailError) {
            setIsSubmitting(false)
            emailError?.data?.statusCode === apiStatuses.badRequest
                ? message.error("Invalid Email Address")
                : message.error(errorMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailSuccess, isEmailError, emailError])

    useEffect(() => {
        setIsDisabled((body === '<p></p>' || !body) && (subject === '<p></p>' || !subject))
    }, [body, subject])

    useEffect(() => {
        return () => { dispatch(setActiveTemplateTypeId(communication)) }
        // eslint-disable-next-line
    }, [dispatch])

    const handlePlaceHolder = (content: string, inputFields: string[] | undefined) => {
        const modifiedContent = content?.replaceAll('<_', '<mark>').replaceAll('_>', '</mark>')
        return modifiedContent?.replace(regexPatterns.matchPlaceholders, (_match, placeholder) => {
            if (inputFields?.length && inputFields?.includes(placeholder)) {
                return ` <mark style="background-color: #FFF7ED;">${placeholder}</mark> `;
            } else return `<${placeholder}>`
        })
    }

    const handleMarkClick = useCallback((event: MouseEvent) => {
        const target = event?.target as HTMLElement
        if (target?.tagName === 'MARK' && target?.style?.backgroundColor === 'rgb(255, 247, 237)') {
            setSelectedMark(target)
            const rect = target.getBoundingClientRect()
            const editorRect = editorRef?.current?.getBoundingClientRect()

            if (editorRect) {
                setSectionPosition({
                    top: rect.bottom - editorRect.top,
                    left: rect.left - editorRect.left,
                })
            }

            // Focus on the input
            setTimeout(() => {
                inputRef.current?.focus()
            }, 0)
        } else {
            setSelectedMark(null)
            setSectionPosition(null)
        }
    }, [])

    useEffect(() => {
        const editorElement = editor?.view.dom
        editorElement?.addEventListener('click', handleMarkClick)
        return () => {
            editorElement?.removeEventListener('click', handleMarkClick)
        }
    }, [editor, handleMarkClick])

    useEffect(() => {
        if (templateTypeId === emailTypes.offer) {

            calculateOfferAttributesWithNoValue(body?.replaceAll('</mark>', '>').replaceAll('<mark data-color="rgb(255, 247, 237)" style="background-color: rgb(255, 247, 237); color: inherit">', '<'), selectedTemplateFields)
        }
    }, [body, selectedTemplateFields, templateTypeId])

    const replaceValue = () => {
        if (!editor || !selectedMark) return
        const markText = selectedMark?.innerText
        const newContent = editor?.getHTML()?.replace(markText, newVal)
        setBody(newContent)
        setInputFieldsValue(inputFieldsValue?.length ? [...inputFieldsValue, { key: markText, value: newVal }] : [{ key: markText, value: newVal }])
        editor?.commands?.setContent(newContent)
        setSelectedMark(null)
        setNewVal('')
        setSectionPosition(null)
    }

    const calculateOfferAttributesWithNoValue = (content: string | undefined, inputFields: string[] | undefined) => {
        let count = 0;
        const transformedContent = content?.replace(/<mark[^>]*>(.*?)<\/mark>/g, (match, contentInsideMark) => {
            if (inputFields?.includes(contentInsideMark)) {
                // If content inside <mark> is in inputFields, replace <mark> with <content>
                return `<${contentInsideMark}>`;
            }
            // If not in inputFields, leave the <mark> tag unchanged
            return match;
        });
        inputFields?.forEach(item => {
            const regex = new RegExp(item, "g");
            const matches = transformedContent?.match(regex);

            if (matches) {
                count += matches.length;
            }
        });
        setNoValueOfferInputsLength(count);
    };

    const onTemplateChange = (value: number) => {
        setInputFieldsValue([])
        setNoValueOfferInputsLength(0);
        const selectedTemplate = alltempelates?.find((template: ITemplate) => template?.id === value)
        if (selectedTemplate) {
            const attributesNames = selectedTemplate?.inputFields
            setSelectedTemplateFields(attributesNames)
            const BodyElement = handlePlaceHolder(selectedTemplate?.body, attributesNames)
            const SubjectElement = handlePlaceHolder(selectedTemplate?.subject, attributesNames)
            editor?.commands?.setContent(BodyElement);
            subjectEditor?.commands?.setContent(SubjectElement);
            setBody(BodyElement);
            setSubject(SubjectElement);
            setNewVal('');
            setSectionPosition(null);
        }
    }

    const handleClose = () => {
        setMinimized(false)
        dispatch(setIsSendEmailOpen(false))
        resetFields()
        setIsSubmitting(false)
    }

    const resetFields = () => {
        editor?.commands?.setContent('')
        subjectEditor?.commands?.setContent('')
        setBody('')
        setSubject('')
        setEmailFiles([])
    }

    const onFinish = async (values: any) => {
        setIsSubmitting(true)
        const { template, ...otherValues } = values
        const emailSubject = subject?.replaceAll('<mark>', '').replaceAll('</mark>', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('&nbsp;', '')
        const emailBody = body?.replaceAll('<mark>', '').replaceAll('</mark>', '').replaceAll('<mark data-color="rgb(255, 247, 237)" style="background-color: rgb(255, 247, 237); color: inherit">', '')
        const apiBody = { ...otherValues, subject: emailSubject, body: emailBody, templateTypeId, attachments: emailFiles }
        const formData = new FormData();
        for (const key in apiBody) {
                const value = apiBody[key];
            if (key === attachments && value.length > 0) {
                    for (let key in value) {
                        formData.append(attachments, value[key].originFileObj)
                    }
                } else if (key !== attachments && value !== undefined && value !== null) {
                    formData.append(key, value);
                }
        }
        const finalBody = { formData, applicationId: Number(candidate?.id) }
        if ((emailBody !== '<p></p>' && emailBody) && (emailSubject !== '<p></p>' && emailSubject)) {
            if (isDemoMode) {
                message.warning({
                    content: <span className="flex text-yellow-800">Demo Mode email successfully sent</span>,
                    icon: <LabIcon className="mr-2" />,
                })
                dispatch(setIsSendEmailOpen(false))
                resetFields()
                setIsSubmitting(false)
            } else {
                const hasInputFields = alltempelates.find(({ id }) => id === template)?.inputFields?.length
                hasInputFields && !noValueOfferInputsLength ? await sendEmail({ ...finalBody, metadata: inputFieldsValue }) : !hasInputFields ? await sendEmail(finalBody) : message.error('input value is missing')
            }
        }
        setIsSubmitting(false)
    }

    const handleRemoveEmailFile = (selectedFile: any) => {
        const updatedFiles = emailFiles.filter(file => file.uid !== selectedFile?.uid)
        setEmailFiles(updatedFiles)
        URL.revokeObjectURL(selectedFile?.url);
    }

    const props: UploadProps = {
        name: 'emailFiles',
        accept: ".doc,.docx,.pdf,.txt,.rtf,.odt,.xls,.xlsx,.od,.ppt,.pptx,.odp,.jpg,.jpeg,.png,.bmp,.gif,.svg,.tif,.tiff,.mp3,.wav,.aac,.flac,.ogg,.mp4,.avi,.mov,.wmv,.mkv,.flv,.webm,.zip,.rar,.7z,.tar,.gz,.html,.css,.xml,.json,.csv",
        multiple: true,
        fileList: emailFiles,
        onChange(info: any) {
            const MAX_TOTAL_SIZE = 20 * 1024 * 1024; // 20MB in bytes
            const totalUploadedSize = info.fileList.reduce((acc: number, file: any) => acc + (file.size || 0), 0);

            if (totalUploadedSize > MAX_TOTAL_SIZE) {
                message.error('Total files size exceeds the maximum limit of 20MB.');
                return;
            }

            const updatedFileList = info.fileList.map((file: any) => {
                if (!file.url && file.originFileObj) {
                    file.url = URL.createObjectURL(file.originFileObj);
                }
                return file;
            });
            setEmailFiles(updatedFileList);
        },
        beforeUpload: reusableBeforeUpload({ fileExec: /\.(|doc|docx|pdf|txt|rtf|odt|xls|xlsx|od|ppt|pptx|odp|jpg|jpeg|png|bmp|gif|svg|tif|tiff|mp3|wav|aac|flac|ogg|mp4|avi|mov|wmv|mkv|flv|webm|zip|rar|7z|tar|gz|html|css|xml|json|csv)$/i })
    };

    return (
        <div className={`${minimized ? 'pt-3.5 pb-0 px-5 max-w-[21.125rem]' : 'p-5 max-w-[32rem]'} transition-all fixed bottom-0 bg-white shadow-xl mx-8 right-0 z-[51] rounded-t-2xl w-[calc(100%-4rem)]`}>
            <div className={`${minimized ? 'pb-3.5' : 'pb-5'} transition-all flex items-center justify-between flex-nowrap`} onClick={minimized ? () => setMinimized(false) : () => { }}>
                <h4 className="text-lg font-medium leading-6 text-gray-900">{templateTypeId === communication ? 'Send Email' : 'Send Offer'}</h4>
                <div className="gap-x-2.5 flex flex-nowrap grow justify-end">
                    <Button className="p-0 h-fit btn-link" onClick={() => setMinimized(!minimized)}>
                        {minimized ? <MaximizeIcon /> : <MinimizeIcon />}
                    </Button>
                    <Button className="p-0 h-fit btn-link" onClick={handleClose}><CloseComposeEmail /></Button>
                </div>
            </div>
            <Divider className="m-0 border-[#DCDCE4]" />
            <div className={`${minimized ? 'h-0' : 'h-[27rem] max-h-[33rem]'} scrollbar-hide overflow-auto transition-all`}>
                <ComposeRow label="From" isConnectedEmail={connectedEmail} />
                <Divider className="m-0 border-[#DCDCE4]" />
                <div className="flex items-center justify-between flex-nowrap">
                    <ComposeRow label="To" text={candidateEmail} />
                    {!ccOn && (
                        <Button onClick={() => setCcOn(true)} className="p-0 btn-link me-2">
                            <span className="text-base font-semibold text-gray-600">Cc</span>
                        </Button>
                    )}
                </div>
                <Divider className="m-0 border-[#DCDCE4]" />
                <Form
                    onFinish={onFinish}
                    name="email-compose"
                    form={form}
                >
                    {ccOn && (
                        <>
                            <Form.Item
                                className="[&>*]:w-full m-0 py-3 [&_label]:!h-full flex items-center [&_label]:after:!content-none [&_label]:before:!content-none"
                                label={<p className="w-20 text-base font-semibold text-gray-600 text-start">Cc</p>}
                                name="cc"
                                rules={[{ pattern: regexPatterns.emailsWithComma, message: 'Enter valid emails separated by comma' }]}
                            >
                                <Input className="px-0" type="text" bordered={false} />
                            </Form.Item>
                            <Divider className="m-0 border-[#DCDCE4]" />
                        </>
                    )}
                    <Form.Item
                        className="[&_.ant-form-item-row]:w-full m-0 py-3 [&_label]:!h-full flex items-center [&_label]:after:!content-none [&_label]:before:!content-none [&_.ant-form-item-explain]:absolute [&_.ant-form-item-explain]:top-0 [&_.ant-form-item-explain]:pointer-events-none"
                        label={<p className="w-20 text-base font-semibold text-gray-600 text-start">Subject</p>}
                        rules={[{ required: true, message: 'Subject is missing' }]}
                    >
                        <EditorContent editor={subjectEditor} />
                    </Form.Item>
                    {(subject === '<p></p>' || !subject) && isSubmitting && <RequestError className='!mt-0'>Subject is missing</RequestError>}
                    <Divider className="m-0 border-[#DCDCE4]" />
                    <Form.Item
                        className="m-0 py-3 [&_label]:!h-full flex items-center [&_label]:after:!content-none [&_label]:before:!content-none"
                        label={<p className="w-20 text-base font-semibold text-gray-600 text-start">Template</p>}
                        name="template"
                    >
                        <Select
                            popupClassName='!w-[15%] p-2 rounded-xl [&_.ant-select-item]:py-2 [&_.ant-select-item]:px-4 [&_.ant-select-item-option-active:not(.ant-select-item-option-disabled)]:bg-indigo-50'
                            onChange={onTemplateChange}
                            loading={isLoading}
                            placeholder='Select'
                            className="text-base bg-transparent border-none round"
                            suffixIcon={<CaretDownGray />}
                        >
                            {isLoading ? (
                                <Option key="loading" value="loading">
                                    <Spin size="small" />
                                </Option>
                            ) : (
                                alltempelates?.map((template, index) => (
                                    <Option key={index} value={template?.id}>{template?.title}</Option>
                                ))
                            )}
                        </Select>
                    </Form.Item>
                    <Divider className="m-0 border-[#DCDCE4]" />
                    <Form.Item className="py-3 m-0">
                        <div ref={editorRef} className="relative">
                            <EditorContent editor={editor} />
                            {sectionPosition && (
                                <div
                                    className="absolute z-10 p-4 space-y-2 bg-white border border-gray-200 rounded-md shadow-md"
                                    style={{
                                        top: `${sectionPosition.top}px`,
                                        left: `${sectionPosition.left}px`,
                                        width: '200px',
                                        gap: '0px',
                                        opacity: '0px',
                                    }}
                                >
                                    <Input
                                        ref={inputRef}
                                        value={newVal}
                                        onChange={(e) => setNewVal(e.target.value)}
                                        className="w-full text-sm border-gray-300 rounded-md shadow-sm"
                                        onPressEnter={replaceValue}
                                        placeholder={`Add`}
                                    />
                                    <Button disabled={!newVal.trim()} size="large" onClick={replaceValue} className="w-full text-sm font-medium shadow-sm btn-primary">
                                        Use
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Form.Item>
                    {(body === '<p></p>' || !body) && isSubmitting && <RequestError className='!mt-0'>Body is missing</RequestError>}
                </Form>
            </div>
            {emailFiles?.length && !minimized ?
                <div className={`flex flex-wrap gap-2.5 mt-3 mb-5 pt-5 ${emailFiles.length > 2 ? 'h-[7.625rem]' : 'h-fit'} ${emailFiles.length >= 3 ? 'overflow-y-auto' : ''}`}>
                    {emailFiles?.map((file) => (
                        <div key={file?.uid} className="relative bg-gray-100 rounded">
                            <img onClick={() => handleRemoveEmailFile(file)} src={getImageUrl('xCircleLighter')} alt="Remove" className="absolute z-10 inline bg-white rounded-full cursor-pointer -top-2 -right-2" />
                            <div className="flex h-[2.875rem] w-[10.688rem]">
                                <GrayFileText className="my-3 ml-2 mr-2.5 w-[14.5%]" />
                                <div className='flex flex-col justify-center text-sm w-[67.5%]' title={file?.name.replace(/\.[^/.]+$/, "")}>
                                    <a href={file?.url} download={file?.name} className="py-1 text-gray-600 line-clamp-1">
                                        {file?.name.replace(/\.[^/.]+$/, "")}
                                    </a>
                                    <p className="mb-1 text-gray-500 uppercase">{file?.name.split('.').pop()}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> : null
            }
            <Divider className="m-0 border-[#DCDCE4]" />
            <div className={`${minimized ? 'h-0 pt-0 overflow-hidden' : 'pt-3'} flex justify-between`}>
                <div className="flex items-center flex-nowrap">
                    <Button
                        form='email-compose'
                        htmlType="submit"
                        disabled={!connectedEmail || EmailLoading || isDisabled}
                        loading={EmailLoading}
                        className={`btn-primary btn-md ${!connectedEmail ? 'cursor-not-allowed' : ''}`}
                    >
                        Send
                        <Check className="inline btn-icon ms-2" />
                    </Button>
                    <TextEditorToolbar editor={editor} />
                    <Upload
                        {...props}
                        customRequest={({ file, onSuccess }) => {
                            // Simulating successful file upload
                            setTimeout(() => {
                                if (onSuccess) {
                                    onSuccess("ok", file as any); // Simulate success after timeout
                                }
                            }, 1000)
                        }}
                        showUploadList={false}
                    >
                        <Button icon={<AttachmentIcon />} className="p-0 btn-link h-fit"></Button>
                    </Upload>
                </div>
            </div>
        </div>
    )
}

function ComposeRow({ label, text, isConnectedEmail }: { label: string; text?: string; isConnectedEmail?: any | null }) {
    return (
        <div className="flex py-3 flex-nowrap">
            <p className="w-20 my-auto text-base font-semibold text-gray-600 h-fit">{label}</p>
            {label === 'From' && !isConnectedEmail ? (
                <div className='flex w-full'>
                    <img className='ml-3 mr-1.5' src={getImageUrl('Exclamation')} alt='error' />
                    <span className="text-base leading-6 text-red-800">
                        No email integrated.
                        <a className="pl-2 text-base font-medium leading-6 text-red-800 underline underline-offset-4" href="/settings/email-integration">
                            Integrate It Now
                        </a>
                    </span>
                </div>
            ) : label === 'From' && isConnectedEmail ? (
                <p className="px-2 py-1 text-base font-normal text-gray-600 rounded-md w-fit bg-indigo-50">{isConnectedEmail?.email}</p>
            ) : (
                <p className="px-2 py-1 text-base font-normal text-gray-600 rounded-md w-fit bg-indigo-50">{text}</p>
            )}
        </div>
    )
}