import React, { useEffect, useRef, useState } from 'react'
import { Input, Button, Form } from 'antd'
import { ReactComponent as BulletIcon } from '../../../../../../shared/assets/images/bullet.svg'
import { ReactComponent as OneRate } from '../../../../../../shared/assets/images/oneRate.svg'
import { ReactComponent as TwoRate } from '../../../../../../shared/assets/images/twoRate.svg'
import { ReactComponent as ThreeRate } from '../../../../../../shared/assets/images/threeRate.svg'
import { ReactComponent as FourRate } from '../../../../../../shared/assets/images/fourRate.svg'
import { ReactComponent as TopRate } from '../../../../../../shared/assets/images/topRate.svg'
import { ReactComponent as EditBullet } from '../../../../../../shared/assets/images/PencilAlt.svg'
import { ReactComponent as Line } from '../../../../../../shared/assets/images/Line.svg'
import { ReactComponent as Discard } from '../../../../../../shared/assets/images/CloseIconBlue.svg'
import { ReactComponent as WhiteTrue } from '../../../../../../shared/assets/images/trueWhite.svg'

import { ReactComponent as OneRateActive } from '../../../../../../shared/assets/images/oneRateActive.svg'
import { ReactComponent as TwoRateActive } from '../../../../../../shared/assets/images/twoRateActive.svg'
import { ReactComponent as ThreeRateActive } from '../../../../../../shared/assets/images/threeRateActive.svg'
import { ReactComponent as FourRateActive } from '../../../../../../shared/assets/images/fourRateActive.svg'
import { ReactComponent as TopRateActive } from '../../../../../../shared/assets/images/topRateActive.svg'
import { ReactComponent as EditBulletActive } from '../../../../../../shared/assets/images/notePencil.svg'
import { IQuestionsProps } from '../../../../../../shared/utils/interfaces/interviewKit'
import { generalEvaluation } from '../../../../../../shared/utils/constantData';


export const Questions: React.FC<IQuestionsProps> = ({ section, sectionIndex, handleRateChange, handleNotes, handleAddNote, onlyView = false }) => {
  const [form] = Form.useForm();
  const [noteValue, setNotValue] = React.useState<string>('');
  const [editingQuestionIndex, setEditingQuestionIndex] = useState<{ section: number, index: number } | null>(null);
  const textAreaRef = useRef<any>({});
  
  useEffect(() => {
    if (editingQuestionIndex !== null) {
      const key = `${editingQuestionIndex.section}_${editingQuestionIndex.index}`;
      const currentTextArea = textAreaRef.current[key]?.resizableTextArea?.textArea;
      if (currentTextArea) {
        currentTextArea.focus();
        const length = currentTextArea?.value?.length;
        currentTextArea?.setSelectionRange(length, length);
      }
    }
  }, [editingQuestionIndex]);
  
  const handleAddNoteClick = (questionIndex: number) => {
    if (noteValue.trim() && handleAddNote) {
      handleAddNote(sectionIndex, questionIndex, form.getFieldValue(`note_${sectionIndex}_${questionIndex}`) || '');
      setNotValue('')
      setEditingQuestionIndex(null);
    }
  };
  const handleWhenNoteChange = (value: string, questionIndex: number) => {
    if (section?.metrics[questionIndex]?.isOpen) {
      form.setFieldsValue({ [`note_${sectionIndex}_${questionIndex}`]: value });
      setNotValue(value);
    }
  }
  
  const startEditing = (sectionIndex: number, questionIndex: number) => {
    setEditingQuestionIndex({ section: sectionIndex, index: questionIndex });
  };

  return (
    <>
      {section?.metrics?.flatMap((question: { question: string, isOpen: boolean, rating: number, note: string }, questionIndex: number) => (
        <div key={questionIndex} className="flex flex-col w-full">
          <div className="flex flex-col items-start w-full gap-4 sm:flex-row">
            {(sectionIndex !== 0 || (onlyView && section?.title !== generalEvaluation)) && <BulletIcon className="w-2 h-2 mt-2 text-gray-400 fill-current shrink-0" />}
            <div className="w-full flex-grow">
              <div className="flex items-start w-full gap-3 pb-2 sm:gap-4 one">
                {!(sectionIndex === 0 && section?.metrics[0]?.isOpen) && !(onlyView && section?.title === generalEvaluation) && (
                  <div className="flex items-center flex-1 w-full gap-2">
                    {!(!onlyView ? (sectionIndex === 0 && section?.metrics[0]?.note?.trim()?.length !== 0) : '') && (
                      <Input.TextArea
                        readOnly
                        value={question?.question}
                        autoSize={{ minRows: 1 }}
                        placeholder='Add note...'
                        className="text-gray-600 text-base font-normal font-['Inter'] leading-normal overflow-hidden flex-1 !border-none !shadow-none !p-0 !bg-transparent hover:!bg-gray-50 transition-colors resize-none w-full !m-0"
                        onFocus={() => {
                          if (sectionIndex === 0 && handleNotes) {
                            handleNotes(sectionIndex, questionIndex, true);
                          }
                        }}
                      />
                    )}
                  </div>
                )}
              </div>

              {(question?.isOpen && !onlyView) && <div className='relative flex w-full bg-gray-100 border rounded-lg two'>
                <div className='flex w-full'>
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    placeholder='Add note..'
                    style={{ border: 'none' }}
                    className="text-gray-600 borderless text-base font-normal font-['Inter'] leading-normal border-r-0 rounded-l-md overflow-hidden flex-1 !border !shadow-none !py-2 !bg-transparent hover:none resize-none w-full"
                    defaultValue={question.note ?? ''}
                    onChange={(e) => handleWhenNoteChange(e?.target?.value, questionIndex)}
                    ref={(el) => {
                      const key = `${sectionIndex}_${questionIndex}`;
                      textAreaRef.current[key] = el; 
                    }}
                  />
                </div>
                <div style={{ borderRadius: '0 0.3125rem 0.3125rem 0' }} className="inset-0 py-2 flex flex-col items-center justify-between rounded-md border-[#d8d9d9] transition-colors">
                  <div className="h-[1.875rem] py-[0.4375rem] justify-center items-center gap-2 inline-flex">
                    <Button onClick={() => { if (handleNotes) { handleNotes(sectionIndex, questionIndex, false) } }} type='text' className="flex items-center justify-between text-indigo-600 text-xs font-medium font-['Inter'] leading-none">
                      Discard <Discard className='ms-2' />
                    </Button>
                  </div>
                  <div className="h-[1.875rem]">
                    <Button
                      onClick={() => handleAddNoteClick(questionIndex)}
                      type='primary'
                      className={`flex items-center ${!form.getFieldValue(`note_${sectionIndex}_${questionIndex}`)?.trim() || !noteValue.trim() ? 'bg-indigo-200 cursor-not-allowed' : 'bg-indigo-600'} text-white text-xs font-medium font-['Inter'] leading-none`}
                      style={{ pointerEvents: !form.getFieldValue(`note_${sectionIndex}_${questionIndex}`)?.trim() || !noteValue.trim() ? 'none' : 'auto' }}
                    >
                      Add <WhiteTrue className='ms-2' />
                    </Button>
                  </div>
                </div>
              </div>}
              {(!question?.isOpen && question?.note) && <div className="inline-flex flex-col items-start justify-start w-full italic max-h-24">
                <div className="text-gray-600 text-base font-bold font-['Inter'] leading-normal">Note</div>
                <div className="self-stretch text-gray-600 text-base font-normal font-['Inter'] leading-normal text-left">{question?.note}</div>
              </div>}
            </div>

            <div className="flex items-center justify-end mt-4 space-x-2 sm:justify-start three sm:mt-0">
              {[OneRate, TwoRate, ThreeRate, FourRate, TopRate].map((RateIcon, index) => {
                const rateValue = index + 1;
                const ActiveRateIcon = [OneRateActive, TwoRateActive, ThreeRateActive, FourRateActive, TopRateActive][index];
                return (
                  <button disabled={onlyView} key={rateValue} onClick={() => { if (handleRateChange) { handleRateChange(sectionIndex, questionIndex, rateValue) } }} className={`py-1.5 ${!onlyView ? 'hover:bg-gray-100' : ''} rounded-full transition-colors`}>
                    {question?.rating === rateValue ? <ActiveRateIcon className="w-4 h-4 text-gray-500" /> : <RateIcon className="w-4 h-4 text-gray-500" />}
                  </button>
                );
              })}
              <Line className='mx-[0.5rem]' />
              <button disabled={onlyView} onClick={() => {
                if (handleNotes && !question?.isOpen) {
                  setNotValue('')
                  startEditing(sectionIndex, questionIndex);
                  handleNotes(sectionIndex, questionIndex, true)
                  form?.setFieldValue(`note_${sectionIndex}_${questionIndex}`, question?.note)
                }
              }} className={`p-1.5 rounded-full transition-colors ${!onlyView ? 'hover:bg-gray-100' : ''
                }`}>
                {
                  onlyView ? <EditBulletActive className={`w-5 h-5`} /> : <EditBullet className={`w-4 h-4`} />
                }
              </button>
            </div>
          </div>

          {questionIndex < section?.metrics?.length - 1 && (
            <hr className={`w-full border-t border-gray-200 ${!onlyView ? 'my-4 ' : 'my-2'} `} />
          )}
        </div>
      ))}
    </>
  )
}
