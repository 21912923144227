
/**
 * createEntityAdapter function returns an object that provides methods for adding, updating, and deleting posts from the state.
 *  These methods can be used by the reducers in the Redux store to manage the state of the posts
 * 
 */
import { apiSlice } from '../../../shared/slices/apiSlice';
import { tagTypes } from '../../../shared/utils/constantData';
import { IRegenerateJobSectionData, IUpdateJobSectionData } from '../../../shared/utils/interfaces/apisInterfaces';
import { IUpdateEssentials } from '../../../shared/utils/interfaces/generateJobFace';
const { job, jobs } = tagTypes;
export const jobApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: any) => ({
        getJobTones: builder.query({
            query: () => '/jobs/tones',
        }),
        getJobLocations: builder.query({
            query: () => '/jobs/locations',
        }),
        getJobById: builder.query({
            query: (id: number) => `/jobs/${id}`,
            providesTags: [job]
        }),
        getPublicJobById: builder.query({
            query: (id: number) => `/jobs/public/${id}`,
        }),
        postJobEssentials: builder.mutation({
            query: (initialJobData: any) => ({
                url: '/jobs',
                method: 'POST',
                body: initialJobData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [jobs]
        }),
        regenerateJobSectionData: builder.mutation({
            query: (initialActionsData: IRegenerateJobSectionData) => ({
                url: '/jobs/regenerate',
                method: 'PATCH',
                body: initialActionsData
            })
        }),
        updateJobSections: builder.mutation({
            query: (initialActionsData: IUpdateJobSectionData) => ({
                url: '/jobs/section',
                method: 'PATCH',
                body: initialActionsData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [jobs]
        }),
        updateJobEssentials: builder.mutation({
            query: ({ initialEssentialData, jobId }: { initialEssentialData: IUpdateEssentials, jobId: number }) => ({
                url: `/jobs/essentials/${jobId}`,
                method: 'PUT',
                body: initialEssentialData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [job, jobs]
        }),
        applyJob: builder.mutation({
            query: (userData: any) => ({
                url: '/applications',
                method: 'POST',
                body: userData
            }),
        }),
        bulkUploadCvs: builder.mutation({
            query: ({ formData, jobId }: { formData: any; jobId: number }) => ({
                url: `/applications/bulkUpload/${jobId}`,
                method: 'POST',
                body: formData
            })
        }),
        settingsNotification: builder.mutation({
            query: (jobId: number) => ({
                url: `/jobs/piplineTooltip/${jobId}`,
                method: 'POST',
            })
        }),
        getStagesByJobId: builder.query({
            query: ({ jobId, globalStageTypeId }: { jobId: number, globalStageTypeId?: number }) => ({
                url: '/jobs/stages',
                params: { jobId, globalStageTypeId }
            })
        }),
    })
})

export const {
    useGetJobTonesQuery,
    useGetJobLocationsQuery,
    useLazyGetJobByIdQuery,
    useGetPublicJobByIdQuery,
    useUpdateJobSectionsMutation,
    usePostJobEssentialsMutation,
    useRegenerateJobSectionDataMutation,
    useUpdateJobEssentialsMutation,
    useApplyJobMutation,
    useBulkUploadCvsMutation,
    useSettingsNotificationMutation,
    useGetStagesByJobIdQuery
} = jobApiSlice
