import React, { useEffect, useState } from 'react';
import { Divider, Button, message } from 'antd';
import CVScore from '../CVScore';
import Comment from './Comment';
import FeedbackEvaluation from './FeedbackEvaluation';
import AddComment from './AddComment';
import { useDispatch, useSelector } from 'react-redux';
import { selectCandidateProfile, setCandidateProfile, selectMentions, setMentions } from '../../../slice/candidateSlice';
import { EmptyList } from '../../../../../shared/components/List/EmptyList';
import { IMentionMember, permissionsActions } from '../../../../../shared/utils/interfaces/AuthInterface';
import { useGetFeedbackQuery, useGetMembersQuery } from '../../../slice/candiddateApiSlice';
import LazyLoader from '../../../../../shared/components/LazyLoader/load';
import { FeedbackTypeId, IGetFeedback } from '../../../../../shared/utils/interfaces/commentsFaces';
import { useNavigate, useParams } from 'react-router-dom';
import { IMemberItem } from '../../../../../shared/utils/interfaces/createJobTabs';
import { regexPatterns } from '../../../../../shared/utils/regexPatterns';
import ModalComponent from '../../../../../shared/components/Modals/ModalComponent';
import ModalBody from '../../../../../shared/components/Modals/ModalBody';
import ChangeStageActions from '../ChangeStageActions';
import Attributes from '../Attributes';
import { errorMessage, roleError } from '../../../../../shared/utils/constantData';
import { WithTooltip } from '../../../../../shared/components/tooltip.tsx/withTootip';
import FeaturesList from './FeaturesList';
import DeleteModal from '../../../../../shared/components/Modals/DeleteModal';
import CandidatesActions from '../CandidatesActions';
import officeIcon from '../../../../../shared/assets/images/Office building.svg'
import { usePermissions } from '../../../../../shared/utils/customHooks/usePermissions';
import ExclamationYellow from '../../../../../shared/assets/images/ExclamationYellow.svg';
import { EvaluationForm } from './CommunicationQuestions';
import { useLazyGetEvaluationByIdQuery, useLazyGetEvaluationKitByCandidateIdQuery } from '../../../../interview/slice/interviewApiSlice';
import { selectEvaluationId, selectEvaluationView, setEvaluationId, setEvaluationKitModal, setEvaluationSections, setEvaluationView, setIsLoadingEvaluationData, setShowPreview, selectEvaluationSections } from '../../../../interview/slice/interviewSlice';
import { IMetrics, ISection } from '../../../../../shared/utils/interfaces/interviewKit';
import { GlobalStageTypesId } from '../../../../../shared/utils/interfaces/candidatesListFace';
import { EvaluationKitModal } from './Evaluation/EvaluationKitModal';
import { useDeleteEvaluationMutation } from '../../../../interview/slice/interviewApiSlice';
import { setDeleteEvaluationModal, selectDeleteEvaluationModal } from "../../../../interview/slice/interviewSlice"
import { apiStatuses } from '../../../../../shared/utils/constantData';
import { PreviewQuestions } from '../../../../interview/components/kit/PreviewQuestions';

const fomratCommentMentions = (text: string, mentionables: IMentionMember[]) => {
    return (
        <>
            {text
                .split(' ')
                .map((word, key) => {
                    const memberName = mentionables.find(({ id }) => Number(word.replace(/^.*\((\d+)\).*$/, '$1')) === id)?.display
                    return (
                        regexPatterns.mentionPattern.test(word) && mentionables.some(({ id }) => Number(word.replace(/^.*\((\d+)\).*$/, '$1')) === id) ?
                            <span key={key} className='text-indigo-700'>{'@' + memberName} </span>
                            : regexPatterns.mentionPattern.test(word) ?
                                word.replace(regexPatterns.mentionWithAtSymbol, '') + ' '
                                :
                                word + ' '
                    )
                })}
        </>
    )
}

const Comments: React.FC = () => {
    const dispatch = useDispatch();
    const candidate = useSelector(selectCandidateProfile);
    const evaluationView = useSelector(selectEvaluationView)
    const evaluationSections = useSelector(selectEvaluationSections)
    const navigate = useNavigate();
    const { applicationId, jobId } = useParams();
    const [kitTitle, setKitTitle] = useState<string>('');
    const {
        data: feedbackData,
        isSuccess: isSuccessFeedback,
        isLoading: isLoadingFeedback,
        isFetching: isFetchingFeedback,
        isError: isErrorFeedback
    } = useGetFeedbackQuery(applicationId);
    const {
        data: membersData,
        isSuccess: isSuccessMembers,
    } = useGetMembersQuery(jobId);
    const [triggerGetEvaluationKit, { isLoading }] = useLazyGetEvaluationKitByCandidateIdQuery();
    const mentionables = useSelector(selectMentions);
    const [viewMore, setViewMore] = useState<boolean>(true);
    const [openWarningModal, setOpenWarningModal] = useState(false)
    const { getInterviewQuestions } = permissionsActions
    const { hasJobPermission } = usePermissions()
    const departmentName = candidate?.department?.name
    const parentDepartment = candidate?.department?.parentDepartment?.name
    const [feedbackEvaluationId, setFeedbackEvaluationID] = useState<Number>()
    const [deleteEvaluation, { isLoading: isLoadingDelete, isSuccess, isError, error }] = useDeleteEvaluationMutation();
    const [getEvaluationById, { isFetching: isLoadingEvaluationData, isSuccess: isSuccessEvaluation, isError: isErrorEvaluation, data: evaluationData }] = useLazyGetEvaluationByIdQuery();
    const deleteModalOpen = useSelector(selectDeleteEvaluationModal)
    const evaluationId = useSelector(selectEvaluationId)
    useEffect(() => {
        if (isSuccessMembers) {
            dispatch(setMentions(membersData?.users?.length !== 0 && Array.isArray(membersData?.users) ?
                membersData?.users?.map(({ id, firstName, lastName, email }: IMemberItem) => {
                    return {
                        id,
                        display: `${firstName}${lastName}`,
                        name: `${firstName} ${lastName}`,
                        email
                    }
                }) : []))
        }
    }, [isSuccessMembers, dispatch, membersData]);

    const handleTooltipClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigate(`/similarApplication/${candidate?.id}`);
    };
    const getEvaluationKitData = async () => {
        try {
            const response = await triggerGetEvaluationKit(applicationId);
            const { isSuccess, data, isError, error } = response;
            if (isSuccess) {
                dispatch(setEvaluationView(true))
                setKitTitle(data?.kit?.title)
                mappingEvaluationData(data?.kit)
                return
            } else if (isError) {
                message.error(error?.data?.message)
            }

        } catch (error) {
            console.error(error);
            message.error(errorMessage)
        }
    }
    const getEvaluationPreview = async (evaluationId: number) => {
        dispatch(setEvaluationId(evaluationId))
        const response = await getEvaluationById(evaluationId)
        if (response?.data?.statusCode === apiStatuses?.success) {
            dispatch(setShowPreview(true))
        }
    }
    const handleEvaluationQuestions = () => {
        if (candidate?.hasKit) {
            getEvaluationKitData()
        } else {
            dispatch(setEvaluationKitModal(true))
        }
    }

    const mappingEvaluationData = (data: any) => {
        let evaluationMetrics = data?.sections?.evaluationMetrics ? data?.sections?.evaluationMetrics : [];
        let aiDefaultQuestions = data?.sections?.aiDefaultQuestions;
        let generalEvaluation = data?.sections?.generalEvaluation;
        const combinedData = evaluationMetrics?.map((section: ISection) => ({
            ...section,
            metrics: section?.metrics?.map((metricData: IMetrics) => ({
                ...metricData,
                metric: metricData?.metric ? metricData?.metric : '',
                isOpen: false
            }))
        }));
        if (aiDefaultQuestions && aiDefaultQuestions?.metrics) {
            combinedData?.push({
                title: aiDefaultQuestions?.title,
                metrics: aiDefaultQuestions?.metrics?.map((metricData: IMetrics) => ({
                    metric: metricData?.metric ? metricData?.metric : '',
                    question: metricData?.question,
                    note: metricData?.note,
                    rating: metricData?.rating,
                    isOpen: false,
                }))
            });
        }

        if (generalEvaluation) {
            combinedData?.unshift({
                title: generalEvaluation?.title,
                metrics: [{
                    note: generalEvaluation?.note,
                    rating: generalEvaluation?.rating,
                    isOpen: false,
                    metric: '',
                    question: ''
                }]
            });
        }

        dispatch(setEvaluationSections(combinedData));
    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(setDeleteEvaluationModal(false))
            message.success(
                <>
                    <span className='text-green-800'>Evaluation is successfully Deleted. </span>
                </>, 5)
            const updatedCandidate = { ...candidate, canEvaluate: true }
            dispatch(setCandidateProfile(updatedCandidate))
        }
        else if (isError) {
            message.error(error?.status === apiStatuses.badRequest ? errorMessage : error?.data?.message || errorMessage, 5)
            dispatch(setDeleteEvaluationModal(false))
        }
        //eslint-disable-next-line
    }, [isError, error, isSuccess, dispatch])
    const handelToggleDeleteModal = () => {
        if (!isLoadingDelete) { dispatch(setDeleteEvaluationModal(!deleteModalOpen)) }
    }

    useEffect(() => {
        return () => {
            dispatch(setEvaluationKitModal(false))
            dispatch(setEvaluationView(false))
            dispatch(setShowPreview(false))
            dispatch(setDeleteEvaluationModal(false))
            dispatch(setEvaluationId(undefined))
        }
    }, [dispatch])

    useEffect(() => {
        if (isLoadingEvaluationData) {
            dispatch(setIsLoadingEvaluationData(true))
        } else if (isSuccessEvaluation) {
            dispatch(setIsLoadingEvaluationData(false))
            setKitTitle(evaluationData?.data?.evaluation?.title)
            mappingEvaluationData(evaluationData?.data?.evaluation)
        } else if (isErrorEvaluation) {
            dispatch(setIsLoadingEvaluationData(false))
            message.error(errorMessage)
        }
        //eslint-disable-next-line
    }, [isLoadingEvaluationData, isSuccessEvaluation, evaluationData, isErrorEvaluation, dispatch])

    const onEditEvaluation = async (feedbackEvaluationId: number) => {
        dispatch(setEvaluationId(feedbackEvaluationId))
        const response = await getEvaluationById(feedbackEvaluationId)
        if (response?.data?.statusCode === apiStatuses?.success) {
            dispatch(setEvaluationView(true))
        }
    }
    const onDeleteEvaluation = (feedbackEvaluationId: number) => {
        setFeedbackEvaluationID(feedbackEvaluationId)
    }
    const handleDelete = async () => {
        await deleteEvaluation(feedbackEvaluationId)
    }
    return (
        <>
            <div className='top-0 flex flex-col w-full mx-auto overflow-auto md:sticky md:h-screen scrollbar-hide p-7'>
                <DeleteModal
                    isVisibleModal={openWarningModal}
                    handleCloseModal={() => setOpenWarningModal(false)}
                    title="Invalid Email Address"
                    content={<p>You can’t email this candidate,<br />no invalid email address available or candidate does't has email</p>}
                    footer={
                        <Button
                            className="w-full py-1 btn-default"
                            onClick={() => setOpenWarningModal(false)}
                        >
                            Ok
                        </Button>} />
                <div className="flex flex-wrap items-center mb-1.5">
                    {candidate?.pipelineStage?.companyStageType?.globalStageTypeId === GlobalStageTypesId.interview ?
                        <WithTooltip
                            visible={!hasJobPermission(candidate?.userRole?.roleName, getInterviewQuestions)}
                            placement='bottom'
                            title={roleError}
                        >
                            <div className='flex flex-wrap items-center grow md:flex-nowrap'>
                                <Button loading={isLoading && candidate?.hasKit} className={`px-3 mb-3 btn-secondary ${!hasJobPermission(candidate?.userRole?.roleName, getInterviewQuestions) || !candidate?.canEvaluate ? '!bg-white' : ''}`} disabled={!hasJobPermission(candidate?.userRole?.roleName, getInterviewQuestions) || !candidate?.canEvaluate} onClick={handleEvaluationQuestions}>
                                    Evaluation Questions
                                </Button>
                            </div>
                        </WithTooltip> : null
                    }
                    <div className='flex flex-wrap items-center justify-end grow md:flex-nowrap'>
                        <CandidatesActions withDropDown={false} candidate={candidate} />
                        <div className="mb-3 ml-2">
                            <ChangeStageActions candidateId={candidate?.id} hiringStageId={candidate?.pipelineHiringStageId} />
                        </div>
                    </div>
                </div>
                <div className='candidate-info'>
                    <div className="flex flex-nowrap">
                        <h3 className='font-bold text-gray-600 me-4'>
                            {candidate?.firstName === null && candidate?.lastName === null
                                ? 'File Name'
                                : `${candidate?.firstName} ${candidate?.lastName}`}
                        </h3>
                        <div className="me-3"><CVScore cvScore={candidate?.cvScore} /></div>
                        {candidate?.isSimilar && <WithTooltip
                            className='w-36'
                            placement='right'
                            colorText='#B45309'
                            backgroundColor='#FEF3C7'
                            width='w-48'

                            title={<>Similarity detection: It appears that the same candidate has applied for multiple positions.
                                <h4 className='mt-3 cursor-pointer' onClick={handleTooltipClick}>Click to compare.</h4></>}
                        >
                            <img onClick={handleTooltipClick} src={ExclamationYellow} alt='Similarity detection' className='inline cursor-pointer' />
                        </WithTooltip>}
                    </div>
                    {departmentName && (
                        <div className='flex mb-4'>
                            <img className="mr-1" src={officeIcon} alt='department' />
                            <div className="text-sm font-normal text-gray-600">
                                {parentDepartment ? `${parentDepartment} / ` : ''}{departmentName}
                            </div>
                        </div>
                    )}
                    <p className='text-gray-600 mb-1.5'>{(viewMore ? candidate?.cvSummary : candidate?.longSummary) ?? 'Score and Summary for this candidate are being processed...'}</p>
                    {candidate?.longSummary &&
                        <Button className='p-0 btn-link' onClick={() => { setViewMore(!viewMore) }}>View {`${viewMore ? 'More' : 'Less'}`}</Button>}
                </div>
                <Divider dashed className='my-4 border-neutral-500' />
                {(candidate?.strengths?.length > 0 || candidate?.weaknesses?.length > 0) &&
                    <>
                        <div className='grid gap-3 md:grid-cols-2'>
                            {candidate?.strengths?.length > 0 && <FeaturesList title="Strength" list={candidate?.strengths} />}
                            {candidate?.weaknesses?.length > 0 && <FeaturesList title="Weakness" list={candidate?.weaknesses} />}
                        </div>
                        <Divider dashed className='my-4 border-neutral-500' />
                    </>
                }
                <Attributes applicationCriteriasEvaluation={candidate?.applicationCriteriasEvaluation} />
                <Divider dashed className='my-4 border-neutral-500' />
                {/* <div className='pb-2 text-indigo-700'>
                    <span className="inline">Comments {`(${commentsData?.comments?.length ?? '0'})`}</span>
                    <img src={getImageUrl('commentIcon')} alt="Comments" className="inline ml-2" />
                </div> */}
                <div>
                    <h3 className='py-3 text-sm font-semibold text-gray-600'>Feedback</h3>
                </div>
                <div className="flex flex-col-reverse flex-grow pt-3 overflow-auto min-h-[14rem]">
                    {isLoadingFeedback || isFetchingFeedback ?
                        <div className='w-full mb-auto'>
                            <LazyLoader />
                            <p className='mt-4 text-base font-normal text-indigo-600'>Retrieving comments..</p>
                        </div>
                        : (isSuccessFeedback && feedbackData?.data?.length > 0) ?
                            feedbackData?.data?.map((feedback: IGetFeedback, key: number) => (<>
                                {feedback?.feedbackTypeId === FeedbackTypeId?.comment ?
                                    <Comment key={key} name={feedback?.name} postedAt={feedback?.createdAt} text={fomratCommentMentions(feedback?.data?.comment as string, mentionables)} />
                                    : <FeedbackEvaluation key={key} feedback={feedback} onShowPreview={(evaluationId: number) => getEvaluationPreview(evaluationId)} onDelete={(evaluationId: number) => onDeleteEvaluation(evaluationId)} onEdit={(evaluationId: number) => onEditEvaluation(evaluationId)} />
                                }
                            </>
                            ))
                            : isErrorFeedback ?
                                <div className="flex items-center justify-center flex-grow h-full">
                                    <EmptyList title="Something went wrong" description='Failed loading comments, please refresh' />
                                </div>
                                :
                                <div className="flex items-center justify-center flex-grow h-full">
                                    <EmptyList title="No feedback yet" description='Be the first to leave a comment or evaluation' />
                                </div>
                    }
                </div>
                <Divider dashed className='my-4 border-neutral-500' />
                <AddComment users={mentionables} />
            </div>
            <DeleteModal
                isVisibleModal={deleteModalOpen}
                handleCloseModal={handelToggleDeleteModal}
                title="Delete Evaluation"
                content={<p>Are you sure you want to delete this Evaluation?<br />This action is permanent and cannot be undone.</p>}
                footer={
                    <div className='grid gap-3 md:grid-cols-2'>
                        <Button
                            loading={isLoadingDelete}
                            className=' btn-md btn-danger'
                            onClick={() => handleDelete()}>
                            Delete
                        </Button>
                        <Button
                            disabled={isLoadingDelete}
                            className='btn-md'
                            htmlType='submit'
                            onClick={handelToggleDeleteModal}>
                            Cancel
                        </Button>
                    </div>}
            />
            <ModalComponent visibleModal={evaluationView}
                handleCloseModal={() => {
                    dispatch(setEvaluationView(false))
                    if (evaluationId) mappingEvaluationData(evaluationData?.data?.evaluation)
                    dispatch(setEvaluationId(undefined))
                }}
                width={1010}
                content={
                    <ModalBody
                        title={
                            <div className=''>
                                {kitTitle && kitTitle}
                            </div>}
                        className="text-start"
                    >
                        <div id="pdf-content">
                            <EvaluationForm kitTitle={kitTitle} />
                        </div>
                    </ModalBody>
                }
            />
            <EvaluationKitModal getEvaluationKit={getEvaluationKitData} isLoading={isLoading} />
            <PreviewQuestions kitTemplateData={evaluationSections} kitTemplateTitle={kitTitle} />
        </>
    );
};

export default Comments;
