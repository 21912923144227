import { createSlice } from "@reduxjs/toolkit";
import {CheckboxDropdownItem} from "../../../shared/utils/interfaces/settingsInterface";
import {templatesTypesData } from '../../../shared/utils/constantData'
const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        plansModalOpen: false,
        isClickingUpdatePlan: false,
        templatePreviewOpen: false,
        settingsModalOpen:false,
        selectedTemplateType: templatesTypesData[0],
    },
    reducers: {
        setPlansModalOpen: (state, action: { payload: boolean }) => {
            state.plansModalOpen = action.payload
        },
        setIsClickingUpdatePlan: (state, action: { payload: boolean }) => {
            state.isClickingUpdatePlan = action.payload
        },
        setTemplatePreviewOpen: (state, action: { payload: boolean }) => {
            state.templatePreviewOpen = action.payload
        },
        setSettingsModalOpen: (state, action: { payload: boolean }) => {state.settingsModalOpen = action.payload},
        setSelectedTemplateType: (state, action) => {state.selectedTemplateType = action.payload}
    }
})

export const { setPlansModalOpen, setTemplatePreviewOpen, setSettingsModalOpen, setSelectedTemplateType, setIsClickingUpdatePlan } = settingsSlice.actions;

export default settingsSlice.reducer;

export const selectPlansModalOpen = (state: { settings: { plansModalOpen: boolean } }) => state.settings.plansModalOpen
export const selectTemplatePreviewOpen = (state: { settings: { templatePreviewOpen: boolean } }) => state.settings.templatePreviewOpen
export const selectSettingsModalOpen = (state: { settings: { settingsModalOpen: boolean } }) => state.settings.settingsModalOpen
export const selectSelectedTemplateType = (state: {settings: {selectedTemplateType: CheckboxDropdownItem}}) => state.settings.selectedTemplateType;
export const selectIsClickingUpdatingPlan = (state: { settings: { isClickingUpdatePlan: boolean } }) => state.settings.isClickingUpdatePlan;