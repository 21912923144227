import React from 'react'

export default function BrokenRoute({ icon, title, message, actions }: { icon: string | React.ReactNode, title: string | React.ReactNode, message: string, actions: React.ReactNode }) {
    return (
        <div className='w-fit mx-auto mt-24 md:mt-36 px-6 text-center'>
            <div className='mb-6'>
                {typeof icon === 'string' ?
                    <img src={icon} className='inline w-32 md:w-48' alt='Page Not Found' />
                    :
                    icon
                }
            </div>
            <div className='mb-6'>
                <h4 className='text-3xl leading-10 mb-6 font-bold text-gray-600'>{title}</h4>
                <p className='text-lg leading-6 font-normal text-gray-600'>{message}</p>
            </div>
            <div>
                {actions}
            </div>
        </div>
    )
}
