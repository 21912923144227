import { IRole } from "./AuthInterface";

export interface createJobTabsFace {
    key: string;
    label: string;
    children: string;

}

export interface SwitchTabsProps {
    tabName: string
}
export interface TabItemFace {
    children?: React.ReactNode,
    activeClass: boolean,
    switchTabs: (() => void) | undefined,
    className?: string,
    tabId?: number,
    isDisabled?: boolean
}

export enum tabs {
    description = 'Description',
    publish = 'Publish',
    candidates = 'Candidates',
    shortlist = 'Shortlist',
    assessment = 'Assessment',
    interview = 'Interview',
    offer = 'Offer',
    hired = 'Hired',
    referenceChecking = 'Reference Checking',
    onboarding = 'Onboarding',
    offerExtended = 'Offer Extended',
    rejected = 'Rejected',
}

export interface IMemberItem {
    id: number
    firstName: string,
    lastName: string,
    email: string,
    roleId: number
    isEditable: boolean,
    isPending?: boolean
    role: IRole
}

export interface IFixedStagesCount{
    unstagedCount: string,
    rejectedCount:string
}

export enum settingsModalTabs {
    hiringPipeline = 'Hiring Pipeline',
    interviewKit = 'Interview Kit',
}