import TextArea from "antd/es/input/TextArea";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { getImageUrl } from "../../utils/functions/images";
import { forwardRef } from "react";

export const DraggableBullet = forwardRef(({ inputValue, index, handleInputChange, handleDeleteInput }: { inputValue: string, index: number, handleDeleteInput: (index: number) => void, handleInputChange: (e: any, index: number) => void }, ref: any) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: index.toString() });
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        touchAction: 'pan-x'
    };
    return (
        <div
            ref={setNodeRef}
            className={`p-0 flex justify-between items-center border-y border-neutral-200`}
            key={index}
        >
            <div className="flex w-10/12">
                <img {...listeners} {...attributes} className="cursor-grabbing" style={style} src={getImageUrl('Button')} alt='drag' />
                <img src={getImageUrl('bullet')} alt='point' className='ml-2 mr-1' />
                <TextArea autoSize ref={ref} className='text-gray-600 leading-4 py-2.5 h-6 text-base' value={inputValue} size='small' bordered={false} onChange={(event) => handleInputChange(event, index)} />
            </div>

            <div className=''>
                <img src={getImageUrl('Trash')} alt='Delete' className='inline cursor-pointer' onClick={() => handleDeleteInput(index)} />
            </div>
        </div>
    )
})