import { ReactNode } from 'react'
import { regexPatterns } from '../../utils/regexPatterns';

export default function ModalBody({ icon, title, subtitle, children, className }: { icon?: string, title: string | React.ReactNode, subtitle?: string | React.ReactNode, children: ReactNode, className?: string }) {
    const { pathWithSlashes, pathExceptDots } = regexPatterns
    const alt = icon?.replace(pathWithSlashes, '').match(pathExceptDots)?.toString()
    return (
        <div className={`text-center py-1 ${className ? className : ''}`}>
            {icon && <img src={icon} alt={alt?.substring(0, alt?.indexOf(","))} className='inline mb-5' />}
            {title && <h3 className='mb-2 text-lg font-medium text-gray-900'>{title}</h3>}
            {subtitle ? <p className='mb-6 text-gray-500 '>{subtitle}</p> : ''}
            {children}
        </div>
    )
}
