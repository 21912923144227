import { useEffect, useState } from "react";
import { IComment } from "../../../../../shared/utils/interfaces/commentsFaces";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ProfileImage from "../../../../../shared/components/NavBar/profileImage";

export default function Comment({ name, postedAt, text }: IComment) {
    const [postedSince, setPostedSince] = useState<string>();

    useEffect(() => {
        dayjs.extend(relativeTime)
        setPostedSince(dayjs().to(dayjs(postedAt)))
    }, [postedAt])

    return (
        <div className='flex items-start mb-5 flex-nowrap pe-2'>
            <ProfileImage name={name} className="me-3.5 -mt-1" />
            <div>
                <div className='flex items-center flex-nowrap mb-1.5'>
                    <span className='text-sm font-semibold text-gray-600 break-all me-2'>{name}</span>
                    <span className='text-xs font-normal text-gray-400 whitespace-nowrap'>{postedSince}</span>
                </div>
                <span className='text-gray-600 break-words min-w-fit'>{text}</span>
            </div>
        </div>
    )
}