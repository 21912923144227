import { Upload, message } from 'antd'; // Assuming you're using antd for file uploads
import { ReusableBeforeUploadProps } from '../interfaces/jobDataSection';


export const reusableBeforeUpload = ({ size, fileExec, drag }: ReusableBeforeUploadProps) => {
  return (file: { size: number, name: string }) => {
    const fileSizeInMB = file.size / (1024 * 1024);
    const formattedSize = formatFileSize(file.size);

    if (!fileExec.test(file.name)) {
      message.error(`The extension of (${file.name}) is not supported!`);
      return Upload.LIST_IGNORE;
    } else if (size && fileSizeInMB > size) {
      message.error(`File size = ${formattedSize} exceeds ${size} MB`);
      return Upload.LIST_IGNORE;
    } else {
      return drag ? false : true;
    }
  };
};

// Helper function to format file size
const formatFileSize = (size: number) => {
  if (size < 1024) {
    return size + ' B';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  } else {
    return (size / (1024 * 1024)).toFixed(2) + ' MB';
  }
};