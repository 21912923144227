import { List } from "antd";
import TextArea from "antd/es/input/TextArea";
import { getImageUrl } from "../../utils/functions/images";
export default function BulletSkeleton({ dragContent, withActionPointsIcon = true }: { dragContent: string, withActionPointsIcon?: boolean }) {
  return (
    <List.Item
      className={`p-0 border-b border-neutral-200 bg-white shadow-md flex`}
      actions={[
        <div className='flex w-full p-0 mx-1 items-center h-11'>
          {withActionPointsIcon && <div className='mr-0 cursor-pointer bulletAction'>
            <img src={getImageUrl('DotsHorizontal')} alt='...' />
          </div>}
          <img src={getImageUrl('Trash')} alt='Delete' className='cursor-pointer bulletAction' />
        </div>
      ]
      }
    >
      <>
        <div className='flex bulletsContainer leading-4 py-2.5 h-11'>
          <img src={getImageUrl('Button')} className="cursor-grabbing" alt='drag' />
        </div>
        <img src={getImageUrl('bullet')} alt='point' className='ml-[0.4rem] mr-[0.38rem]' />
        <TextArea autoSize className='text-gray-600 leading-4 py-2.5 h-11 text-base !bg-white' value={dragContent} size='small' bordered={false} />
      </>
    </List.Item>
  )
};