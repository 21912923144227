export interface settingsLayoutFace {
    title: string,
    subtitle: string | React.ReactNode,
    children: React.ReactNode
}

export interface subFace {
    id: number,
    name: string
}

export enum settingsTabs {
    profile = 'profile',
    email = 'templates/email',
    interview = "templates/interview",
    automatedActions = 'automated-actions',
    pipelines = 'pipelines',
    linkDomain = 'link-domain',
    emailIntegration = "email-integration",
    department = "department"
}

export enum automationActionsTabs {
    templatesActions = 'templates-actions',
    myActions = 'my-actions',
}

export interface ItemplateAttributes {
    field: string,
    isInput: boolean,
}
export enum automationTemplateCard {
    resumeReceived = 1,
    welcome = 2,
    generic = 3,
}
export interface IActionTemplateCard {
    id: automationTemplateCard,
    name: string,
    body: string
}

export interface IAction {
    id: number
    automationTemplate: IActionTemplateCard,
    isEnabled: boolean,
    blockers: string[],
    automationTarget: string
}
export interface IJob {
    id: number;
    title: string;
    jobStatus: {
        id: number;
    };
}

export interface CheckboxDropdownItem {
    key: string;
    label: string;
    prefix?: string;
    parentKey?: string;
    master?: boolean;
    children?: CheckboxDropdownItem[];
    disabled?: boolean,
    errorContent?: React.ReactNode,
    tooltip?: { isVisiable: boolean, title: string },
    visibleTopDivider?: boolean,
    visibleBottomDivider?:boolean
}

export interface CheckboxDropdownProps {
    items: CheckboxDropdownItem[];
    title: string;
    isRadio?: boolean;
    isLoading?: boolean;
    isError?: boolean;
    refetch?: () => void;
    checkedKeys: CheckboxDropdownItem[];
    setCheckedItems: any;
    withSelectedTitleItems?: boolean;
    withGrayDropDownIcon?: boolean;
    dropDownButtonClassName?: string;
    dropDownRootClassName?: string;
    failed?: boolean,
}

export enum automatedActionStatus {
    pending = "pending",
    processing = "processing",
    done = "done",
    failed = "failed",
    canceled = "canceled"
}
export enum emailTypes {
    communication = 1,
    offer = 2,
}
export enum TemplateTypes {
    email = "1",
    interview = "2",
}
