import { ReactComponent as Sparkles } from '../../../../shared/assets/images/Sparkles.svg'
import { Button, Input, Divider, message } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { useGenerateNewTemplateSectionWithAiMutation } from '../../slice/interviewApiSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedTemplateData, setGenerateSectionWithAI, setIsClickingUseContent, setOldSelectedTemplateData, setSelectedTemplateData } from '../../slice/interviewSlice';
import { IKit, IKitSection } from '../../../../shared/utils/interfaces/interviewKit';
import { apiStatuses, errorMessage } from '../../../../shared/utils/constantData';
import LazyLoader from '../../../../shared/components/LazyLoader/load';

export const GenerateNewSectionWithAI = () => {
    const dispatch = useDispatch()
    const [sectionTitle, setSectionTitle] = useState('');
    const templateData = useSelector(selectSelectedTemplateData)
    const [GenerateNewTemplateSectionWithAi, { isLoading, isSuccess, isError, error, data }] = useGenerateNewTemplateSectionWithAiMutation()
    const updatedTemplateDataWithSections = (isOldData?: boolean) => {
        const AllSections = [
            ...templateData?.data?.sections?.evaluationMetrics,
            { ...data.data, editable: true } as IKitSection,
        ];
        const updatedTemplate = {
            ...templateData,
            data: {
                ...templateData?.data,
                sections: {
                    ...templateData?.data?.sections,
                    evaluationMetrics: AllSections,
                },
            },
        }
        isOldData ? dispatch(setOldSelectedTemplateData((updatedTemplate as IKit)))
            : dispatch(setSelectedTemplateData((updatedTemplate as IKit)))
    }
    useEffect(() => {
        if (isSuccess) {
            updatedTemplateDataWithSections();
            updatedTemplateDataWithSections(true);
            dispatch(setGenerateSectionWithAI(false))
            dispatch(setIsClickingUseContent(true))
        }
        else if (isError) message.error(error?.status === apiStatuses.badRequest ? errorMessage : error?.data?.message || errorMessage, 5)
        //eslint-disable-next-line
    }, [isSuccess, isLoading, isError, error, data, dispatch])
    const handleTitleChange = (e: { target: { value: string } }) => {
        setSectionTitle(e.target.value);
    };

    const handleGenerateNewSection = async () => {
        await GenerateNewTemplateSectionWithAi({ templateId: templateData?.id, title: sectionTitle })
    }

    return (
        <div className='mx-auto my-8 w-full md:w-[65%]'>
            <div className="h-7 flex items-center text-xs font-bold text-[#111827] uppercase">
                New section
            </div>
            <div className="fixed top-0 left-0 z-10 w-screen h-screen p-5 md:static md:z-auto bg-light-100 md:bg-transparent md:h-auto md:w-auto md:p-0">
                <div className='w-full flex flex-col justify-between bg-white rounded-xl shadow-lg mt-3 mb-8 h-[93vh] md:h-auto overflow-y-hidden'>
                    <LazyLoader className={!isLoading ? 'opacity-0' : 'opacity-1'} />
                    <div className='py-3 px-5 h-full flex flex-col justify-between'>
                    <div>
                        <div className="flex items-center justify-end gap-1 pb-3">
                            <Button
                                htmlType="button"
                                type="link"
                                className="p-0 text-sm font-medium text-gray-500"
                                disabled={isLoading}
                                onClick={() => setSectionTitle('')}
                            >
                                Clear All
                            </Button>
                            <Divider type="vertical" className='h-4 border-gray-400' />
                            <Button
                                htmlType="button"
                                type="link"
                                className="flex items-center p-0 text-sm font-medium text-indigo-700"
                                disabled={isLoading}
                                onClick={() => dispatch(setGenerateSectionWithAI(false))}
                            >
                                <span> Discard Changes</span>
                                <CloseOutlined className="align-middle" />
                            </Button>
                        </div>
                        <div className='flex w-full'>
                            <Input
                                className={`text-base font-light text-gray-900 placeholder-gray-300 border !border-x-transparent !border-gray-200 transition-none`}
                                value={sectionTitle}
                                placeholder="Write section title"
                                onChange={handleTitleChange}
                            />
                        </div>
                    </div>

                    <div className="mt-5">
                        <Button
                            className='flex items-center justify-between w-full gap-2 btn-md btn-primary md:w-auto'
                            disabled={!sectionTitle.trim() || isLoading}
                            onClick={handleGenerateNewSection}
                        >
                            <p>Generate</p><Sparkles />
                        </Button>
                    </div>
                    </div>
                </div>
            </div>
        </div>            
    )
}
