import { Button, Divider, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { getImageUrl } from "../../../../shared/utils/functions/images";
import { DraggableBullet } from "../../../../shared/components/EditMode/DraggableBullet";
import { IKitSection, IKitQuestion, IMetrics, IKit } from "../../../../shared/utils/interfaces/interviewKit";
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as Exclamation } from '../../../../shared/assets/images/Exclamation.svg'
import { selectIsClickingUseContent, selectOldSelectedTemplateData, selectSelectedTemplateData, setIsClickingUseContent, setOldSelectedTemplateData, setSelectedTemplateData } from "../../slice/interviewSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import BulletSkeleton from "../../../../shared/components/EditMode/BulletSkeleton";
const EditMode = ({ section }: { section: IKitSection }) => {
    const dispatch = useDispatch()
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor), useSensor(KeyboardSensor))
    const templateData = useSelector(selectSelectedTemplateData)
    const [dragContent, setDragContent] = useState<string | null>(null)
    const lastBulletRef = useRef<HTMLInputElement>(null);
    const [focusedBullet, setFocusedBullet] = useState<boolean>(false);
    const oldTemplateData = useSelector(selectOldSelectedTemplateData)
    const isClickingUseContent = useSelector(selectIsClickingUseContent)
    
    useEffect(() => {
        if (!focusedBullet) {
            lastBulletRef.current?.focus();
            setFocusedBullet(true);
        }
    }, [lastBulletRef, focusedBullet]);

    const updatedTemplateDataWithQuestions = (sectionId: number, updatedQuestions: IKitQuestion[]) => {
        const updatedKitTemplate = {
            ...templateData,
            data: {
                title: templateData?.data.title,
                sections: {
                    evaluationMetrics: templateData?.data?.sections?.evaluationMetrics.map((section) => sectionId === section?.id ? { ...section, metrics: updatedQuestions } : section)
                }
            }
        }
        dispatch(setSelectedTemplateData((updatedKitTemplate)))
        dispatch(setIsClickingUseContent(true))
    }
    const updatedTemplateDataWithSections = (updatedSections: IKitSection[], isOldData?: boolean) => {
        const updatedTemplate = {
            ...templateData,
            data: {
                ...templateData.data,
                sections: {
                    ...templateData.data.sections,
                    evaluationMetrics: updatedSections,
                },
            },
        }
        isOldData ? dispatch(setOldSelectedTemplateData((updatedTemplate as IKit)))
            : dispatch(setSelectedTemplateData((updatedTemplate as IKit)))
    }

    const handleQuestionChange = (e: { target: { value: string } }, index: number) => {
        const updatedQuestion = section?.metrics?.map((question, bulletIndex) => index === bulletIndex ? { ...question, question: e.target.value } : question)
        updatedTemplateDataWithQuestions(section?.id, updatedQuestion)
    }

    const handleDeleteQuestion = (index: number) => {
        const updatedQuestion = section?.metrics?.filter((_, bulletIndex) => index !== bulletIndex)
        updatedTemplateDataWithQuestions(section?.id, updatedQuestion)
    }

    const handleAddQuestion = () => {
        setFocusedBullet(false);
        const questions = section?.metrics
        const updatedQuestion = [...questions as IMetrics[], { metric: '', question: '', isNewQuestion: true }]
        updatedTemplateDataWithQuestions(section?.id, updatedQuestion as IKitQuestion[])
        setTimeout(() => lastBulletRef?.current?.focus(), 0);
    }

    const onDragStart = (event: { active: { id: string } }) => {
        const questions = section?.metrics;
        if (questions?.length) setDragContent(questions[Number(event.active.id)].question);
    }

    const onDragEnd = (event: any) => {
        setDragContent(null);
        const { active, over } = event;
        if (active?.id !== over?.id) {
            const questions = section?.metrics
            const oldIndex = questions?.findIndex((input, index) => index.toString() === active?.id) as number
            const newIndex = questions?.findIndex((input, index) => index.toString() === over?.id) as number
            const updatedQuestion = arrayMove(questions as IMetrics[], oldIndex, newIndex);
            updatedTemplateDataWithQuestions(section?.id, updatedQuestion as IKitQuestion[])
        };
    }

    const handleNewSectionTitle = (e: { target: { value: string } }) => {
        const updatedSections = templateData?.data?.sections?.evaluationMetrics.map((sec) =>
            sec?.id === section?.id
                ? { ...sec, title: e.target.value, hasNoTitle: !e.target.value.trim() }
                : sec
        );
        updatedTemplateDataWithSections(updatedSections)
    }

    const discard = () => {
        const updatedSections = templateData?.data?.sections?.evaluationMetrics?.map((sec) =>
            sec?.id === section?.id ? { ...oldTemplateData?.data?.sections?.evaluationMetrics?.find(oldSec => oldSec?.id === sec?.id), editable: false } : sec
        );
        updatedTemplateDataWithSections(updatedSections as IKitSection[]);

    };

    const sectionsTitlesValidation = () => {
        const updatedSections = templateData?.data?.sections?.evaluationMetrics.map((sec) => sec?.id === section?.id && !sec?.title ? { ...section, hasNoTitle: true } : { ...sec, hasNoTitle: false })
        updatedTemplateDataWithSections(updatedSections)
    }

    const useContent = () => {
        sectionsTitlesValidation()
        if (section?.title) {
            setFocusedBullet(false);
            const updatedQuestions = section?.metrics?.map(({ metric, question }: IKitQuestion) => { return { metric, question } })
            const updatedSections = templateData?.data?.sections?.evaluationMetrics?.map((sec) =>
                sec?.id === section.id ? { ...sec, editable: false, metrics: updatedQuestions, updated: true } : sec
            );
            updatedTemplateDataWithSections(updatedSections as IKitSection[]);
            updatedTemplateDataWithSections(updatedSections as IKitSection[], true);
        }
    }

    const handleClearAll = (sectionId: number) => {
        const updatedSections = templateData?.data?.sections?.evaluationMetrics?.map((sec) =>
            sec?.id === sectionId ? { ...sec, metrics: [] } : sec
        );
        updatedTemplateDataWithSections(updatedSections);
    }
    return (
        <div className="fixed top-0 left-0 z-10 w-screen h-screen p-5 md:static md:z-auto bg-light-100 md:bg-transparent md:h-auto md:w-auto md:p-0">
            <div className="w-full flex flex-col justify-between bg-white py-3 px-5 rounded-xl shadow-lg mt-3 h-[93vh] md:h-auto">
                <div>
                    <div className="flex items-center justify-end gap-1 pb-3 border-b">
                        <Button
                            htmlType="button"
                            type="link"
                            className="p-0 text-sm font-medium text-gray-500"
                            onClick={() => handleClearAll(section?.id)}
                        >
                            Clear All
                        </Button>
                        <Divider type="vertical" className='h-4 border-gray-400' />
                        <Button
                            htmlType="button"
                            type="link"
                            className="flex items-center p-0 text-sm font-medium text-indigo-700"
                            onClick={discard}
                        >
                            <span> Discard Changes</span>
                            <CloseOutlined className="align-middle" />
                        </Button>
                    </div>
                    {
                        section?.isNew && (
                            <div className="flex items-center w-full">
                                <Input
                                    className={`text-base font-light text-gray-900 placeholder-gray-300 border-none transition-none ${section.hasNoTitle ? 'w-1/5' : 'w-full'}`}
                                    value={section.title}
                                    placeholder="Write section title"
                                    onChange={handleNewSectionTitle}
                                />
                                {section.hasNoTitle && (
                                    <div className="flex items-center w-1/4 gap-1 mt-2">
                                        <Exclamation />
                                        <p className="text-sm font-semibold text-red-800">Missing section title</p>
                                    </div>
                                )}
                            </div>
                        )
                    }
                    <div>
                        <DndContext collisionDetection={closestCenter} autoScroll={false} onDragStart={(e) => onDragStart(e)} onDragEnd={(e) => onDragEnd(e)} sensors={sensors}>
                            <SortableContext items={section?.metrics?.map(({ question }) => question)} strategy={verticalListSortingStrategy}>
                                {
                                    section?.metrics?.map(({ question, isNewQuestion }: IKitQuestion, index: number) => {
                                        return (
                                            <div key={index}>
                                                <DraggableBullet
                                                    key={index}
                                                    index={index}
                                                    inputValue={question}
                                                    handleDeleteInput={() => handleDeleteQuestion(index)}
                                                    handleInputChange={(e) => handleQuestionChange(e, index)}
                                                    ref={index === section?.metrics.length - 1 && isNewQuestion ? lastBulletRef : null}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </SortableContext>
                            <DragOverlay>
                                {dragContent ? (
                                    <BulletSkeleton dragContent={dragContent} withActionPointsIcon={false} />
                                ) : null}
                            </DragOverlay>
                        </DndContext>
                    </div>

                    <div className="h-10 px-0 bg-white md:h-auto">
                        <div className="flex border-y border-neutral-200">
                            <img
                                src={getImageUrl("grayPoint")}
                                alt="point"
                                className="ml-[0.4rem] mr-[0.38rem]"
                            />
                            <TextArea
                                autoSize
                                readOnly
                                bordered={false}
                                onClick={() => handleAddQuestion()}
                                className="px-0 py-3 text-base font-light text-gray-900 placeholder-gray-300 addNewInput bg-none"
                                placeholder="Write new bullet point"
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-5 mb-3">
                    <Button
                        className="flex items-center justify-between w-full btn-md btn-primary md:w-auto"
                        disabled={section?.metrics?.some(({ question }) => !question) || !section?.metrics.length || !isClickingUseContent}
                        onClick={useContent}
                    >

                        Use Content<img src={getImageUrl('Check')} className='inline ms-2' alt="Save" />
                    </Button>
                </div>
            </div>
        </div >
    );
};

export default EditMode;