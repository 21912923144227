import { Form, Button, message } from 'antd'
import { ICreateEvaluation, IEvaluationKitTitle, IMetrics, ISection } from '../../../../../shared/utils/interfaces/interviewKit';
import { useDispatch, useSelector } from 'react-redux';
import { selectEvaluationId, selectEvaluationSections, setEvaluationId, setEvaluationSections, setEvaluationView } from '../../../../interview/slice/interviewSlice';
import { Questions } from './Evaluation/Questions';
import { useCreateEvaluationMutation, useEditEvaluationMutation } from '../../../../interview/slice/interviewApiSlice';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { selectCandidateProfile, setCandidateProfile } from '../../../slice/candidateSlice';
import { apiStatuses, errorMessage } from '../../../../../shared/utils/constantData';



export const EvaluationForm: React.FC<IEvaluationKitTitle> = ({ kitTitle }) => {
    const dispatch = useDispatch()
    const { applicationId } = useParams();
    const evaluationId = useSelector(selectEvaluationId)
    const [createEvaluation, { data, isSuccess, isLoading, isError, error }] = useCreateEvaluationMutation();
    const [editEvaluation, { data: editData, isSuccess: isSuccessEditing, isLoading: isLoadingEditing, isError: isErrorEditing, error: EditingError }] = useEditEvaluationMutation();
    const [isStartEditing, setIsStartEditing] = useState(false)
    const evaluationSections = useSelector(selectEvaluationSections)
    const candidate = useSelector(selectCandidateProfile)

    const handleNotes = (sectionIndex: number, questionIndex: number, isNoteOpens: boolean) => {
        setIsStartEditing(true)
        const updatedSections = evaluationSections?.map((section: ISection) => ({
            ...section,
            metrics: section?.metrics?.map((metric: IMetrics) => ({
                ...metric,
                isOpen: false
            }))
        }));

        if (isNoteOpens) {
            updatedSections[sectionIndex].metrics[questionIndex].isOpen = true;
        }

        dispatch(setEvaluationSections(updatedSections));
    }
    const handleRateChange = (sectionIndex: number, questionIndex: number, rate: number) => {
        setIsStartEditing(true)
        const updatedSections = evaluationSections?.map((section: ISection) => ({
            ...section,
            metrics: section?.metrics?.map((metric: IMetrics) => ({ ...metric }))
        }));
        updatedSections[sectionIndex].metrics[questionIndex].rating = rate;
        dispatch(setEvaluationSections(updatedSections));
    };
    const handleAddNote = (sectionIndex: number, questionIndex: number, note: string) => {
        setIsStartEditing(true)
        const updatedSections = evaluationSections?.map((section: ISection, idx: number) => {
            if (idx === sectionIndex) {
                return {
                    ...section,
                    metrics: section?.metrics?.map((metric: IMetrics, qIdx: number) => {
                        if (qIdx === questionIndex) {
                            return { ...metric, note, isOpen: false };
                        }
                        return metric;
                    })
                };
            }
            return section;
        });

        dispatch(setEvaluationSections(updatedSections));
    };

    const handleEvaluationSubmit = async () => {
        const apiData: ICreateEvaluation = {
            applicationId: Number(applicationId),
            title: kitTitle,
            sections: evaluationSections
        }
        evaluationId ? await editEvaluation({ ...apiData, evaluationId }) : await createEvaluation(apiData);
    }

    const isSaveBtnDisable = useMemo(() => {
        const hasNotesOrRates = evaluationSections?.some((section: ISection) =>
            section?.metrics?.some((metric: IMetrics) => (metric?.note && metric?.note?.trim() !== '') || metric?.rating)
        );
        return evaluationId ? !isStartEditing : !hasNotesOrRates
    }, [evaluationId, evaluationSections, isStartEditing])

    useEffect(() => {
        if (isSuccess) {
            message.success(data?.message);
            dispatch(setCandidateProfile({ ...candidate, canEvaluate: false }))
            dispatch(setEvaluationView(false))
        } else if (isError) {
            message.error(error?.data?.statusCode === apiStatuses.badRequest ? errorMessage : error?.data?.message);
        }
        //eslint-disable-next-line
    }, [isSuccess, isError, error, data, dispatch])

    useEffect(() => {
        if (isSuccessEditing) {
            message.success(editData?.message);
            dispatch(setEvaluationView(false))
            setIsStartEditing(false)
            dispatch(setEvaluationId(undefined))
        } else if (isErrorEditing) {
            message.error(EditingError?.data?.statusCode === apiStatuses.badRequest ? errorMessage : EditingError?.data?.message);
        }
    }, [isSuccessEditing, isErrorEditing, editData, dispatch, EditingError])

    return (
        <div className="w-full max-w-[60rem] p-4 mx-auto sm:p-6">
            <Form layout="vertical" className="space-y-8">
                {evaluationSections?.map((section: ISection, sectionIndex: number) => (
                    <div key={sectionIndex}>
                        <div className="flex items-center justify-between mb-[1.0625rem]"></div>
                        <h2 className="text-gray-900 text-xs font-semibold font-['Inter'] uppercase leading-none mb-2">{section.title}</h2>
                        <hr className="my-2 border-t border-gray-200" />
                        <Questions
                            section={section}
                            sectionIndex={sectionIndex}
                            handleNotes={handleNotes}
                            handleRateChange={handleRateChange}
                            handleAddNote={handleAddNote} />
                    </div>
                ))}
                <div className="flex justify-end mt-6">
                    <Button loading={isLoading || isLoadingEditing} onClick={handleEvaluationSubmit} type='primary'
                        className={`flex items-center ${isSaveBtnDisable ? 'bg-indigo-200 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'} text-white text-xs font-medium font-['Inter'] leading-none`}
                        style={{ pointerEvents: isSaveBtnDisable ? 'none' : 'auto' }} htmlType="submit">
                        Save Evaluation
                    </Button>
                </div>
            </Form>
        </div>
    )
}

