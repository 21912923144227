
import { createSlice } from "@reduxjs/toolkit"
import { ITemplate } from "../../../shared/utils/interfaces/apisInterfaces"
import { emailTypes } from "../../../shared/utils/interfaces/settingsInterface"

const templatesSlice = createSlice({
    name: 'templates',
    initialState: {
        templates:[],
        selectedTemplate: {},
        isAddingNewTemplate: false,
        attributesNames: [],
        attributes: [],
        selectTemplateType: false,
        activeTemplateTypeId: emailTypes.communication
    },
    reducers: {
        setTemplates: (state, action) => {
            state.templates = action.payload
        },
        setSelectedTemplate: (state, action) => {
            state.selectedTemplate = action.payload
        },
        setIsAddingNewTemplate: (state, action: { payload: boolean }) => {
            state.isAddingNewTemplate = action.payload
        },
        setAttributesNames: (state, action: { payload: [] }) => {
            state.attributesNames = action.payload
        },
        setAttributes: (state, action: { payload: [] }) => {
            state.attributes = action.payload
        },
        setSelectTemplateType: (state, action: { payload: boolean }) => {
            state.selectTemplateType = action.payload
        },
        setActiveTemplateTypeId: (state, action: { payload: emailTypes }) => {
            state.activeTemplateTypeId = action.payload
        },
    },
})

export const { setSelectedTemplate, setIsAddingNewTemplate, setAttributesNames, setAttributes, setTemplates, setSelectTemplateType, setActiveTemplateTypeId } = templatesSlice.actions

export default templatesSlice.reducer

export const selectTemplates = (state: { templates: { templates: ITemplate[] } }) => state.templates.templates
export const selectSelectedTemplate = (state: { templates: { selectedTemplate: ITemplate } }) => state.templates.selectedTemplate
export const selectIsAddingNewTemplate = (state: { templates: { isAddingNewTemplate: boolean } }) => state.templates.isAddingNewTemplate
export const selectAttributesNames = (state: { templates: { attributesNames: string[] } }) => state.templates.attributesNames
export const selectAttributes = (state: { templates: { attributes: { field: string, isInput: boolean }[] } }) => state.templates.attributes
export const selectTemplateType = (state: { templates: { selectTemplateType: boolean } }) => state.templates.selectTemplateType
export const selectActiveTemplateTypeId = (state: { templates: { activeTemplateTypeId: emailTypes } }) => state.templates.activeTemplateTypeId